<template>
  <b-modal
    :id="MODAL_ID"
    :title="title"
    size="lg"
    centered
    ok-title="Save"
    @ok.prevent="onSave"
  >
    <b-row>
      <b-col cols="3">
        Selected Sizes:
      </b-col>
      <b-col>
        <b-form-tag
          v-for="(size, index) in selectedSizes"
          :key="index"
          class="p-2 mb-1"
          variant="info"
          @remove="onRemoveSize(index)"
        >
          {{ size.width }}x{{ size.height }}
        </b-form-tag>
      </b-col>
    </b-row>
    <div class="my-3" />
    <b-row>
      <b-col cols="6">
        <label>Customers</label>
        <Multiselect
          v-model="selectedCustomer"
          track-by="name"
          label="name"
          placeholder="Select Customer"
          :options="customers"
          @input="onChangeCustomer"
        />
      </b-col>
      <b-col
        cols="6"
        class="align-content-end"
      >
        <Multiselect
          v-model="tags"
          placeholder="Filter Templates"
          multiple
          :loading="isLoading"
          :options="getTags"
          :close-on-select="false"
          :show-labels="false"
          @input="onChangeTags"
        />
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-between mt-3">
          <div class="d-flex">
            <label>Templates</label>
            <div class="mx-3" />
            <b-form-radio-group
              id="radio-group-2"
              v-model.number="selectedTypeTemplate"
              name="radio-sub-component"
            >
              <b-form-radio value="0">
                All
              </b-form-radio>
              <b-form-radio value="1">
                Horizontal
              </b-form-radio>
              <b-form-radio value="2">
                Vertical
              </b-form-radio>
            </b-form-radio-group>
          </div>
          <b-checkbox
            v-model="galleryView"
            class="float-right"
            switch
            @change="$refs.templatesSelect.activate()"
          >
            Gallery view
          </b-checkbox>
        </div>
        
        <div class="d-flex">
          <Multiselect
            ref="templatesSelect"
            v-model="selectedTemplates"
            :class="['templates-dropdown', {'gallery-view': galleryView}]"
            track-by="name"
            label="name"
            :placeholder="`Select Template${isMultiSizes ? 's' : ''}`"
            :multiple="isMultiSizes"
            :loading="isLoading"
            :options="getTemplatesOptionsFormatted"
            :close-on-select="!isMultiSizes"
            :show-labels="false"
            @select="onChangeSelectTemplates"
          >
            <template #selection="{ values, isOpen }">
              <span 
                v-if="values.length"
                v-show="!isOpen"
                class="multiselect__single"
              >
                {{ values.length }} selected
              </span>
            </template>
            <template #option="props">
              <div 
                v-if="props.option.name !== 'Select all'"
                class="select-template-thumbnail-container"
              >
                <div class="select-template-thumbnail">
                  <img
                    :src="props.option.thumbnail"
                    :alt="props.option.name"
                  >
                </div>
              </div>
              <span>{{ props.option.$groupLabel || props.option.name }}</span>
            </template>
          </Multiselect>
          <b-btn
            v-if="isMultiSizes"
            class="w-20"
            variant="primary"
            :disabled="!selectedTemplates.length"
            @click="onAddTemplatesSize"
          >
            <i class="c-icon cil-plus" /> Add
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <div class="my-3" />
    <b-row>
      <b-col cols="12">
        <label>Add Custom Size:</label>
        <div class="d-flex align-items-center">
          <b-input-group class="mr-1">
            <b-form-input
              v-model.number="customSize.width"
              type="number"
              @input="(value) => onCustomSizeChange(value, CUSTOM_SIZE_INPUT_TYPES.WIDTH_INPUT)"
            />
            <b-input-group-append>
              <b-input-group-text style="border-right: none;">
                <b-icon icon="x" />
              </b-input-group-text>
            </b-input-group-append>

            <b-form-input
              v-model.number="customSize.height"
              type="number"
              @input="(value) => onCustomSizeChange(value, CUSTOM_SIZE_INPUT_TYPES.HEIGHT_INPUT)"
            />
          </b-input-group>
          <b-btn
            class="w-10 mr-3"
            style="height: 38px"
            :disabled="!customSize.width || !customSize.height"
            @click="onAddCustomSize"
          >
            Add
          </b-btn>
          <b-form-checkbox
            v-model="maintainAspectRatio"
            class="w-100"
            @change="onMaintainAspectRatioChange"
          >
            Maintain aspect ratio
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label>Add Default Size:</label>
        <Multiselect
          :options="CUSTOM_WIDTH_AND_HEIGHTS"
          :track-by="'id'"
          label="name"
          placeholder="Select size"
          @select="onAddCustomSize"
        >
          <template #singleLabel="props">
            {{ props.option.width + 'x' + props.option.height }}
          </template>
          <template #option="props">
            {{ props.option.width + 'x' + props.option.height }}
          </template>
        </Multiselect>
      </b-col>
    </b-row>
    <div class="my-2" />
    <!--    Should be hidden at the moment   -->
    <!--    <b-row>-->
    <!--      <b-col cols="4">-->
    <!--        <label>Align Content</label>-->
    <!--        <b-form-select-->
    <!--          v-model="alignContent.originX"-->
    <!--        >-->
    <!--          <b-form-select-option-->
    <!--            v-for="direction in ALIGN_CONTENT"-->
    <!--            :key="direction"-->
    <!--            :value="direction"-->
    <!--          >-->
    <!--            {{ ALIGN_CONTENT_HORIZONTAL[direction] }}-->
    <!--          </b-form-select-option>-->
    <!--        </b-form-select>-->
    <!--      </b-col>-->
    <!--      <b-col cols="4">-->
    <!--        <label>Align Content</label>-->
    <!--        <b-form-select-->
    <!--          v-model="alignContent.originY"-->
    <!--        >-->
    <!--          <b-form-select-option-->
    <!--            v-for="direction in ALIGN_CONTENT"-->
    <!--            :key="direction"-->
    <!--            :value="direction"-->
    <!--          >-->
    <!--            {{ ALIGN_CONTENT_VERTICAL[direction] }}-->
    <!--          </b-form-select-option>-->
    <!--        </b-form-select>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <div class="my-2" />
    <b-row>
      <b-col cols="12">
        <label>Choose Background</label>
        <Multiselect
          v-model="selectedBackground"
          class="background-dropdown"
          track-by="id"
          label="name"
          placeholder="Choose Background"
          :options="getBackgroundFields"
        >
          <template #singleLabel="props">
            <div class="background-dropdown-label">
              <div class="background-dropdown-label__image">
                <img
                  :src="props.option.thumbnail"
                  :alt="props.option.name"
                >
              </div>
              <p class="background-dropdown-label__text">
                {{ props.option.name }}
              </p>
            </div>
          </template>
          <template #option="props">
            <div class="background-dropdown-option">
              <div class="background-dropdown-option__image">
                <img
                  :src="props.option.thumbnail"
                  :alt="props.option.name"
                >
              </div>
              <p class="background-dropdown-option__text">
                {{ props.option.name }}
              </p>
            </div>
          </template>
        </Multiselect>
      </b-col>
    </b-row>
    <div class="my-2" />
    <!--    Should be hidden at the moment   -->
    <!--    <b-row>-->
    <!--      <b-col cols="6">-->
    <!--        <label>Size</label>-->
    <!--        <b-form-select-->
    <!--          v-model="backgroundProperties.size"-->
    <!--        >-->
    <!--          <b-form-select-option :value="BACKGROUND_PROPERTIES.SIZE.COVER">-->
    <!--            Cover-->
    <!--          </b-form-select-option>-->
    <!--          <b-form-select-option :value="BACKGROUND_PROPERTIES.SIZE.CONTAIN">-->
    <!--            Contain-->
    <!--          </b-form-select-option>-->
    <!--          <b-form-select-option :value="BACKGROUND_PROPERTIES.SIZE.FROM_DESTINATION_TEMPLATE">-->
    <!--            Use position from destination template-->
    <!--          </b-form-select-option>-->
    <!--        </b-form-select>-->
    <!--      </b-col>-->
    <!--      <b-col cols="6">-->
    <!--        <label>Position</label>-->
    <!--        <b-form-select-->
    <!--          v-model="backgroundProperties.position"-->
    <!--        >-->
    <!--          <b-form-select-option :value="BACKGROUND_PROPERTIES.POSITION.LEFT_AND_TOP">-->
    <!--            Top Left-->
    <!--          </b-form-select-option>-->
    <!--          <b-form-select-option :value="BACKGROUND_PROPERTIES.POSITION.CENTER">-->
    <!--            Center-->
    <!--          </b-form-select-option>-->
    <!--          <b-form-select-option :value="BACKGROUND_PROPERTIES.POSITION.FROM_DESTINATION_TEMPLATE">-->
    <!--            Use position from destination template-->
    <!--          </b-form-select-option>-->
    <!--        </b-form-select>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <template #modal-footer="{ cancel, ok }">
      <div class="w-100 d-flex justify-content-end">
        <!--    Should be hidden at the moment   -->
        <!--        <div class="d-flex">-->
        <!--          <b-form-checkbox-->
        <!--            v-model="isUseDestinationTemplateFields"-->
        <!--            class="mr-5"-->
        <!--          >-->
        <!--            Use destination template fields-->
        <!--          </b-form-checkbox>-->
        <!--          <b-form-checkbox v-model="isProportionalDimension">-->
        <!--            Treat as proportional-->
        <!--          </b-form-checkbox>-->
        <!--        </div>-->
        <div class="d-flex">
          <b-button
            @click="cancel"
          >
            Cancel
          </b-button>
          <div class="mx-1" />
          <b-button
            variant="primary"
            :disabled="!selectedSizes.length || isSaving"
            @click="ok"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { copyAndResizeEventBroker, EVENTS } from '@frontend/group/modules/copy-and-resize/event-broker';
import TEMPLATE_API from '@frontend/services/api/template';
import { BACKGROUND_PROPERTIES } from '@frontend/group/modules/copy-and-resize/handlers/resize-background-handler';
import { FIELD_TYPE_BACKGROND_THEME_IMAGE } from '@frontend/constants/type-fields-of-template';
import { getTypeDimension } from '@frontend/group/modules/copy-and-resize/helpers';
import { TYPE_DIMENSION } from '@frontend/group/modules/copy-and-resize/handlers/resize-template-handler';
import { ALIGN_CONTENT } from '@frontend/group/modules/copy-and-resize/helpers';
import { FIELD_NAME_IMAGE_AREA } from '@frontend/constants/name-fields-of-template';

const MODAL_ID = 'copy-and-resize-template-modal';

const ALIGN_CONTENT_HORIZONTAL = {
  [ALIGN_CONTENT.START]: 'Left',
  [ALIGN_CONTENT.CENTER]: 'Middle',
  [ALIGN_CONTENT.END]: 'Right'
}

const ALIGN_CONTENT_VERTICAL = {
  [ALIGN_CONTENT.START]: 'Top',
  [ALIGN_CONTENT.CENTER]: 'Middle',
  [ALIGN_CONTENT.END]: 'Bottom'
}

const CUSTOM_SIZE_INPUT_TYPES = {
  WIDTH_INPUT: 'width',
  HEIGHT_INPUT: 'height',
}

const CUSTOM_WIDTH_AND_HEIGHTS = [
  { id: 1, width: 160, height: 600, name: '160x600' },
  { id: 2, width: 180, height: 150, name: '180x150' },
  { id: 3, width: 300, height: 250, name: '300x250' },
  { id: 4, width: 300, height: 600, name: '300x600' },
  { id: 5, width: 320, height: 50, name: '320x50' },
  { id: 6, width: 468, height: 60, name: '468x60' },
  { id: 7, width: 728, height: 90, name: '728x90' },
  { id: 8, width: 320, height: 480, name: '320x480' },
  { id: 9, width: 300, height: 1050, name: '300x1050' },
  { id: 10, width: 320, height: 100, name: '320x100' },
  { id: 11, width: 336, height: 280, name: '336x280' },
  { id: 12, width: 800, height: 250, name: '800x250' },
  { id: 13, width: 970, height: 250, name: '970x250' },
  { id: 14, width: 970, height: 90, name: '970x90' },
];

const SELECT_ALL_TEMPLATES_OPTION_NAME = 'Select all';

export default {
  components: { Multiselect },
  props: {
    customers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ALIGN_CONTENT: Object.values(ALIGN_CONTENT),
      ALIGN_CONTENT_HORIZONTAL,
      ALIGN_CONTENT_VERTICAL,
      MODAL_ID,
      CUSTOM_SIZE_INPUT_TYPES,
      BACKGROUND_PROPERTIES,
      CUSTOM_WIDTH_AND_HEIGHTS,
      title: 'Copy and Resize Template',
      isMultiSizes: true,
      isLoading: false,
      isSaving: false,
      preview: null,
      isProportionalDimension: false,
      isUseDestinationTemplateFields: false,
      maintainAspectRatio: false,
      selectedTypeTemplate: 0,
      selectedCustomer: null,
      selectedTemplates: [],
      selectedField: null,
      templatesFilteredByTags: {},
      templates: {},
      selectedSizes: [],
      customSize: {
        width: '',
        height: ''
      },
      backgroundProperties: {
        selectedField: null,
        size: BACKGROUND_PROPERTIES.SIZE.COVER,
        position: BACKGROUND_PROPERTIES.POSITION.LEFT_AND_TOP,
      },
      canvasSize: {
        width: 0,
        height: 0,
      },
      alignContent: {
        originX: ALIGN_CONTENT.START,
        originY: ALIGN_CONTENT.START,
      },
      fields: [],
      objects: [],
      inputGroup: '',
      tags: [],
      galleryView: false
    }
  },
  computed: {
    getTemplatesOptionsFormatted() {
      return this.getTemplatesOptions.map(option => {
        if (option.width && option.height) {
          return {
            ...option,
            name: `${option.name} (${option.width}x${option.height})`
          };
        }
        return option;
      });
    },
    customLabel(option) {
      console.log(option);
      if (!option) return '';
      return `${option.width}x${option.height}`;
    },
    getTemplatesOptions: function () {
      const templates = this.getTemplates();
      console.log(templates);
      return this.isMultiSizes
          ? [{ name: SELECT_ALL_TEMPLATES_OPTION_NAME }, ...templates]
          : templates;
    },
    getDefaultSizeOption: function () {
      return CUSTOM_WIDTH_AND_HEIGHTS.map((item, index) => {
          return {
            id: index,
            text: item.width + 'X' + item.height
          };
    })},
    getBackgroundFields() {
      return this.fields.filter(field => [FIELD_TYPE_BACKGROND_THEME_IMAGE].includes(field.type));
    },
    selectedBackground: {
      get() {
        return this.fields.find(option => option.id === this.backgroundProperties.selectedField) || null;
      },
      set(option) {
        this.backgroundProperties.selectedField = option ? option.id : null;
      }
    },
    getTags() {
      const tags = this.templates[this.selectedCustomer?.id]
          ?.filter(item => item.tags)
          ?.map(item => item.tags);

      return [...new Set(tags)].length ? [...new Set(tags)] : [];
    },
  },
  mounted() {
    copyAndResizeEventBroker.on(EVENTS.INIT, this.init.bind(this));
    copyAndResizeEventBroker.on(EVENTS.CLOSED, this.onClose.bind(this));
  },
  methods: {
    init({ title, fields, isMultiSizes, canvasSize, objects }) {
      this.title = title;
      this.isMultiSizes = isMultiSizes;
      this.fields = fields;
      this.canvasSize = canvasSize;
      this.objects = objects;
      this.isSaving = false;

      this.backgroundProperties.selectedField = this.getBackgroundFields
        .find(field => field.name === FIELD_NAME_IMAGE_AREA)?.id || null;

      this.$bvModal.show(MODAL_ID);
    },
    getTypeDimension(width, height) {
      if (this.isProportionalDimension) {
        return TYPE_DIMENSION.PROPORTIONAL;
      }

      return getTypeDimension(width, height, this.canvasSize);
    },
    onRemoveSize(index) {
      this.selectedSizes = this.selectedSizes.filter((item, _index) => _index !== index);
    },
    onAddTemplatesSize() {
      this.selectedSizes = [
        ...this.selectedSizes, 
        ...this.selectedTemplates.map(
          (template) => {
            const { width, height } = template;

            return {
              width,
              height,
              type: this.getTypeDimension(width, height),
              template,
            }
          }
        )
      ];
      this.selectedTemplates = [];
    },
    onAddCustomSize(option = null) {
      let width = option?.width;
      let height = option?.height;
      if(this.customSize.width && this.customSize.height){
        width = this.customSize.width;
        height = this.customSize.height;
      }

      if (!width || !height) {
        console.warn("Invalid size dimensions: width or height is missing.");
        return;
      }

      const size = {
        width,
        height,
        type: this.getTypeDimension(width, height),
      };

      if (this.isMultiSizes) {
        this.selectedSizes.push(size);
      } else {
        this.selectedSizes = [size];
      }

      this.resetCustomSize();
    },

    resetCustomSize() {
      this.customSize = {
        width: '',
        height: ''
      };
    },
    onChangeSelectTemplates() {
      if (this.isMultiSizes) {
        const isSelectedAll = !!this.selectedTemplates.find(selectedOption => selectedOption.name === SELECT_ALL_TEMPLATES_OPTION_NAME);

        if (isSelectedAll) {
          this.selectedTemplates = this.selectedTemplates.length === 1 ? this.getTemplates() : [];
        }

        return;
      }

      const { width, height } = this.selectedTemplates
      const size = {
        width,
        height,
        type: this.getTypeDimension(width, height),
        template: this.selectedTemplates
      }

      this.selectedSizes = [size];
      this.selectedTemplates = null;
    },
    async onChangeCustomer(selectedCustomer) {
      const selectedCustomerId = selectedCustomer.id;
      this.tags = [];
      this.onChangeTags()
      if (this.templates[selectedCustomerId]) return;

      this.selectedTemplates = [];
      await this.fetchTemplates(selectedCustomerId)
    },
    addGroup() {
      this.objects.push({
        name: this.inputGroup,
        isGroup: true,
        visible: false,
        objects: [],
      });
    },
    async fetchTemplates(id = null) {
      const selectedCustomerId = id || this.value.customer.id
      this.isLoading = true;

      try {
        const { data } = await TEMPLATE_API.getTemplatesByCustomer(selectedCustomerId);

        this.templates = {
          ...this.templates,
          [selectedCustomerId]: data
        };

        this.templatesFilteredByTags = {
          ...this.templatesFilteredByTags,
          [selectedCustomerId]: data
        };

      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    onClose() {
      this.$nextTick(() => {
        this.$bvModal.hide(MODAL_ID);
      });
    },
    onSave() {
      this.isSaving = true;

      copyAndResizeEventBroker.fire(EVENTS.SAVED, {
        isUseDestinationTemplateFields: this.isUseDestinationTemplateFields,
        selectedSizes: this.selectedSizes,
        alignContent: this.alignContent,
        backgroundProperties: this.backgroundProperties,
        isMultiSizes: this.isMultiSizes,
        objects: this.objects,
      })
    },
    onMaintainAspectRatioChange(value) {
      if (value) {
        this.customSize.width = this.canvasSize.width;
        this.customSize.height = this.canvasSize.height;
      }
    },
    onCustomSizeChange(value, inputType) {
      if (!this.maintainAspectRatio) return;
      
      const currentWidth = this.canvasSize.width;
      const currentHeight = this.canvasSize.height;

      const aspectRatio = currentWidth / currentHeight;

      let newWidth;
      let newHeight;

      if (inputType === CUSTOM_SIZE_INPUT_TYPES.WIDTH_INPUT) {
        newHeight = +value / aspectRatio;
        this.customSize.height = Math.round(newHeight);
        
      } else {
        newWidth = +value * aspectRatio;
        this.customSize.width = Math.round(newWidth);
        
      }
    },
    getTemplates() {
      const templates = this.templatesFilteredByTags[this.selectedCustomer?.id] || [];

      if (this.selectedTypeTemplate) {
        const handler = this.selectedTypeTemplate === 1 ?
            ({width, height}) =>  height < width :
            ({width, height}) =>  height > width;
        return templates.filter(handler);
      }

      return templates;
    },
    onChangeTags() {
      let filteredTemplates = [];

      if (!this.tags.length) {
        return this.templatesFilteredByTags = {
          ...this.templatesFilteredByTags,
          [this.selectedCustomer?.id]: this.templates[this.selectedCustomer?.id]
        }
      }

      this.tags.forEach(tag => {
        const templatesByTag = this.templates[this.selectedCustomer?.id].filter(item => item.tags === tag);

        filteredTemplates = filteredTemplates.concat(templatesByTag);
      });

      this.templatesFilteredByTags[this.selectedCustomer?.id] = filteredTemplates;
    },
  }
}
</script>

<style lang="scss">
  .background-dropdown-label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    
    &__image {
      position: relative;
      padding-top: 50px;
      width: 100%;
      max-width: 50px;
      
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
    &__text {
      margin-bottom: 0;
    }
  }
  .background-dropdown-option {
    display: flex;
    align-items: center;
    column-gap: 10px;
    
    &__image {
      position: relative;
      width: 100%;
      max-width: 30px;
      padding-top: 30px;
      
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
    &__text {
      margin: 0;
    }
  }
  
  .background-dropdown {
    .multiselect__select {
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
