var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.MODAL_ID,
        title: _vm.title,
        size: "lg",
        centered: "",
        "ok-title": "Save",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.onSave.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel, ok }) {
            return [
              _c("div", { staticClass: "w-100 d-flex justify-content-end" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("b-button", { on: { click: cancel } }, [
                      _vm._v("\n          Cancel\n        "),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mx-1" }),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          disabled: !_vm.selectedSizes.length || _vm.isSaving,
                        },
                        on: { click: ok },
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3" } }, [
            _vm._v("\n      Selected Sizes:\n    "),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            _vm._l(_vm.selectedSizes, function (size, index) {
              return _c(
                "b-form-tag",
                {
                  key: index,
                  staticClass: "p-2 mb-1",
                  attrs: { variant: "info" },
                  on: {
                    remove: function ($event) {
                      return _vm.onRemoveSize(index)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(size.width) +
                      "x" +
                      _vm._s(size.height) +
                      "\n      "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("label", [_vm._v("Customers")]),
              _vm._v(" "),
              _c("Multiselect", {
                attrs: {
                  "track-by": "name",
                  label: "name",
                  placeholder: "Select Customer",
                  options: _vm.customers,
                },
                on: { input: _vm.onChangeCustomer },
                model: {
                  value: _vm.selectedCustomer,
                  callback: function ($$v) {
                    _vm.selectedCustomer = $$v
                  },
                  expression: "selectedCustomer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "align-content-end", attrs: { cols: "6" } },
            [
              _c("Multiselect", {
                attrs: {
                  placeholder: "Filter Templates",
                  multiple: "",
                  loading: _vm.isLoading,
                  options: _vm.getTags,
                  "close-on-select": false,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeTags },
                model: {
                  value: _vm.tags,
                  callback: function ($$v) {
                    _vm.tags = $$v
                  },
                  expression: "tags",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between mt-3" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("label", [_vm._v("Templates")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mx-3" }),
                    _vm._v(" "),
                    _c(
                      "b-form-radio-group",
                      {
                        attrs: {
                          id: "radio-group-2",
                          name: "radio-sub-component",
                        },
                        model: {
                          value: _vm.selectedTypeTemplate,
                          callback: function ($$v) {
                            _vm.selectedTypeTemplate = _vm._n($$v)
                          },
                          expression: "selectedTypeTemplate",
                        },
                      },
                      [
                        _c("b-form-radio", { attrs: { value: "0" } }, [
                          _vm._v("\n              All\n            "),
                        ]),
                        _vm._v(" "),
                        _c("b-form-radio", { attrs: { value: "1" } }, [
                          _vm._v("\n              Horizontal\n            "),
                        ]),
                        _vm._v(" "),
                        _c("b-form-radio", { attrs: { value: "2" } }, [
                          _vm._v("\n              Vertical\n            "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-checkbox",
                  {
                    staticClass: "float-right",
                    attrs: { switch: "" },
                    on: {
                      change: function ($event) {
                        return _vm.$refs.templatesSelect.activate()
                      },
                    },
                    model: {
                      value: _vm.galleryView,
                      callback: function ($$v) {
                        _vm.galleryView = $$v
                      },
                      expression: "galleryView",
                    },
                  },
                  [_vm._v("\n          Gallery view\n        ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("Multiselect", {
                  ref: "templatesSelect",
                  class: [
                    "templates-dropdown",
                    { "gallery-view": _vm.galleryView },
                  ],
                  attrs: {
                    "track-by": "name",
                    label: "name",
                    placeholder: `Select Template${
                      _vm.isMultiSizes ? "s" : ""
                    }`,
                    multiple: _vm.isMultiSizes,
                    loading: _vm.isLoading,
                    options: _vm.getTemplatesOptionsFormatted,
                    "close-on-select": !_vm.isMultiSizes,
                    "show-labels": false,
                  },
                  on: { select: _vm.onChangeSelectTemplates },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function ({ values, isOpen }) {
                        return [
                          values.length
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !isOpen,
                                      expression: "!isOpen",
                                    },
                                  ],
                                  staticClass: "multiselect__single",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(values.length) +
                                      " selected\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          props.option.name !== "Select all"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "select-template-thumbnail-container",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select-template-thumbnail",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: props.option.thumbnail,
                                          alt: props.option.name,
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                props.option.$groupLabel || props.option.name
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedTemplates,
                    callback: function ($$v) {
                      _vm.selectedTemplates = $$v
                    },
                    expression: "selectedTemplates",
                  },
                }),
                _vm._v(" "),
                _vm.isMultiSizes
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "w-20",
                        attrs: {
                          variant: "primary",
                          disabled: !_vm.selectedTemplates.length,
                        },
                        on: { click: _vm.onAddTemplatesSize },
                      },
                      [
                        _c("i", { staticClass: "c-icon cil-plus" }),
                        _vm._v(" Add\n        "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("label", [_vm._v("Add Custom Size:")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mr-1" },
                  [
                    _c("b-form-input", {
                      attrs: { type: "number" },
                      on: {
                        input: (value) =>
                          _vm.onCustomSizeChange(
                            value,
                            _vm.CUSTOM_SIZE_INPUT_TYPES.WIDTH_INPUT
                          ),
                      },
                      model: {
                        value: _vm.customSize.width,
                        callback: function ($$v) {
                          _vm.$set(_vm.customSize, "width", _vm._n($$v))
                        },
                        expression: "customSize.width",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-input-group-append",
                      [
                        _c(
                          "b-input-group-text",
                          { staticStyle: { "border-right": "none" } },
                          [_c("b-icon", { attrs: { icon: "x" } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-form-input", {
                      attrs: { type: "number" },
                      on: {
                        input: (value) =>
                          _vm.onCustomSizeChange(
                            value,
                            _vm.CUSTOM_SIZE_INPUT_TYPES.HEIGHT_INPUT
                          ),
                      },
                      model: {
                        value: _vm.customSize.height,
                        callback: function ($$v) {
                          _vm.$set(_vm.customSize, "height", _vm._n($$v))
                        },
                        expression: "customSize.height",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-btn",
                  {
                    staticClass: "w-10 mr-3",
                    staticStyle: { height: "38px" },
                    attrs: {
                      disabled: !_vm.customSize.width || !_vm.customSize.height,
                    },
                    on: { click: _vm.onAddCustomSize },
                  },
                  [_vm._v("\n          Add\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "w-100",
                    on: { change: _vm.onMaintainAspectRatioChange },
                    model: {
                      value: _vm.maintainAspectRatio,
                      callback: function ($$v) {
                        _vm.maintainAspectRatio = $$v
                      },
                      expression: "maintainAspectRatio",
                    },
                  },
                  [_vm._v("\n          Maintain aspect ratio\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("label", [_vm._v("Add Default Size:")]),
              _vm._v(" "),
              _c("Multiselect", {
                attrs: {
                  options: _vm.CUSTOM_WIDTH_AND_HEIGHTS,
                  "track-by": "id",
                  label: "name",
                  placeholder: "Select size",
                },
                on: { select: _vm.onAddCustomSize },
                scopedSlots: _vm._u([
                  {
                    key: "singleLabel",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              props.option.width + "x" + props.option.height
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              props.option.width + "x" + props.option.height
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("label", [_vm._v("Choose Background")]),
              _vm._v(" "),
              _c("Multiselect", {
                staticClass: "background-dropdown",
                attrs: {
                  "track-by": "id",
                  label: "name",
                  placeholder: "Choose Background",
                  options: _vm.getBackgroundFields,
                },
                scopedSlots: _vm._u([
                  {
                    key: "singleLabel",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          { staticClass: "background-dropdown-label" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "background-dropdown-label__image",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: props.option.thumbnail,
                                    alt: props.option.name,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "background-dropdown-label__text",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.option.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          { staticClass: "background-dropdown-option" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "background-dropdown-option__image",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: props.option.thumbnail,
                                    alt: props.option.name,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "background-dropdown-option__text",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.option.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedBackground,
                  callback: function ($$v) {
                    _vm.selectedBackground = $$v
                  },
                  expression: "selectedBackground",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }