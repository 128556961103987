<template>
  <div>
    <b-list-group>
      <draggable
        :list="layersOrdered"
        group="layers"
        @end="dragEnd"
      >
        <b-list-group-item
          v-for="field in layersOrdered"
          :key="field.id"
          button
          :active="isActive(field)"
          :variant="field.relatedCanvasObject ? '' : 'light'"
          :class="['d-flex justify-content-start align-items-center px-2 py-1', {'font-weight-bold': !!field.relatedCanvasObject}]"
          @click="setActiveObject(field.relatedCanvasObject)"
        >
          <b-icon 
            v-if="!!field.relatedCanvasObject"
            :icon="getIcon(field.relatedCanvasObject)"
            @click.stop="handleLayerVisibility(field.relatedCanvasObject)" 
          />
          <b-icon
            v-if="isOffScreen(field.relatedCanvasObject)"
            v-b-tooltip.hover.viewport.noninteractive="'This item is off-screen. Click to center it'"
            icon="bullseye"
            class="rounded-circle bg-primary"
            variant="light"
            @click="reposition(field.relatedCanvasObject)"
          />
          <div
            v-b-tooltip.hover.d500
            :title="field.name"
            :disabled="isTooltipDisabled(field.name)"
            class="flex-grow-1 text-center text-truncate"
          >
            {{ field.name }}
          </div>
          <b-icon icon="grip-vertical" />
        </b-list-group-item>
      </draggable>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { editorControlEventBroker, EVENTS } from "@frontend/group/modules/editor-control-popup/event-broker";

const LAYER_VISIBILITY_ACTION = 'layerVisibility'

export default {
  name: 'LayersModule',

  components: {
    draggable,
  },

  props: {
    isTabActive: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    layersOrdered: [],
  }),

  computed: {
    ...mapGetters('layout', [
      'layers',
      'target',
      'selectedInstance',
    ]),
    getIcon() {
      return object => object?.visible ? 'eye' : 'eye-slash';
    }
  },

  watch: {
    isTabActive() {
      this.rerenderList();
    },
  },

  methods: {
    isActive (element) {
      return element.relatedCanvasObject?.element_id === this.target?.element_id
    },
    setActiveObject (object) {
      if (!object) {
        return
      }
      this.selectedInstance.canvas.setActiveObject(object)
      this.selectedInstance.canvas.renderAll()
    },
    dragEnd () {
      this.renderChanges()
      this.saveChanges()
    },
    renderChanges () {
      [...this.layersOrdered]
          .reverse()
          .filter(layer => layer.relatedCanvasObject)
          .forEach(layer => {
            (layer.relatedCanvasObject.group || layer.relatedCanvasObject).bringToFront()
          })
      this.selectedInstance.canvas.renderAll()
    },
    saveChanges () {
      const usersFieldsOrder = _.map(this.layersOrdered, 'id')
      this.selectedInstance.canvas.fire('layers:changed', {
        instance_id: this.selectedInstance.id,
        key: 'users_fields_order',
        value: usersFieldsOrder,
      })
    },
    reorderLayers(instanceId) {
      const usersFieldsOrder = JSON.parse(this.selectedInstance.data.users_fields_order || null);

      this.selectedInstance.canvas.fire('layers:update', instanceId);

      if (usersFieldsOrder) {
        this.layersOrdered = _.sortBy(this.layers, layer => usersFieldsOrder.findIndex(id => id === layer.id));
        return;
      }

      this.layersOrdered = this.layers;
    },
    isTooltipDisabled(name) {
        return name.length < 25;
    },
    rerenderList() {
      if (this.isTabActive) {
        this.reorderLayers(this.selectedInstance.id);
      }
    },
    handleLayerVisibility(object) {
      this.setActiveObject(object);
      
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
        action: LAYER_VISIBILITY_ACTION,
        object
      });
    },
    isOffScreen (object) {
      return object?.selectable && !object.isOnScreen() && !isNaN(object.left + object.top)
    },
    reposition (object) {
      this.selectedInstance.canvas.centerObject(object)
      this.selectedInstance.canvas.fire('object:moving', { target: object })
      this.renderChanges()
    },
  }
}
</script>

<style scoped lang="scss">

</style>
