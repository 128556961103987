import { render, staticRenderFns } from "./ExportPageNumerationTab.vue?vue&type=template&id=cdb9e542&scoped=true"
import script from "./ExportPageNumerationTab.vue?vue&type=script&lang=js"
export * from "./ExportPageNumerationTab.vue?vue&type=script&lang=js"
import style0 from "./ExportPageNumerationTab.vue?vue&type=style&index=0&id=cdb9e542&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb9e542",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_testing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cdb9e542')) {
      api.createRecord('cdb9e542', component.options)
    } else {
      api.reload('cdb9e542', component.options)
    }
    module.hot.accept("./ExportPageNumerationTab.vue?vue&type=template&id=cdb9e542&scoped=true", function () {
      api.rerender('cdb9e542', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/Export/ExportPageNumerationTab.vue"
export default component.exports