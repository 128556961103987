import { render, staticRenderFns } from "./AiEditorTab.vue?vue&type=template&id=32869c30&scoped=true"
import script from "./AiEditorTab.vue?vue&type=script&lang=js"
export * from "./AiEditorTab.vue?vue&type=script&lang=js"
import style0 from "./AiEditorTab.vue?vue&type=style&index=0&id=32869c30&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32869c30",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_testing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32869c30')) {
      api.createRecord('32869c30', component.options)
    } else {
      api.reload('32869c30', component.options)
    }
    module.hot.accept("./AiEditorTab.vue?vue&type=template&id=32869c30&scoped=true", function () {
      api.rerender('32869c30', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/EditorControl/components/AiEditorTab.vue"
export default component.exports