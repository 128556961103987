var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "mr-1",
      attrs: { size: "sm", variant: "primary", text: "Templates" },
    },
    [
      _c(
        "b-dropdown-form",
        [
          _c(
            "b-list-group",
            { attrs: { flush: "" } },
            [
              _c(
                "b-list-group-item",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { size: "sm" },
                      on: { change: _vm.onSelectAllTemplates },
                      model: {
                        value: _vm.isSelectedAllTemplate,
                        callback: function ($$v) {
                          _vm.isSelectedAllTemplate = $$v
                        },
                        expression: "isSelectedAllTemplate",
                      },
                    },
                    [_vm._v("\n          All templates\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.templates, function (template) {
                return _c(
                  "b-list-group-item",
                  {
                    key: template.instanceId,
                    attrs: {
                      disabled:
                        _vm.getCurrentInstanceId === template.instanceId,
                    },
                  },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          disabled:
                            _vm.getCurrentInstanceId === template.instanceId,
                          size: "sm",
                        },
                        on: { change: _vm.onSelectTemplate },
                        model: {
                          value: template.isSelected,
                          callback: function ($$v) {
                            _vm.$set(template, "isSelected", $$v)
                          },
                          expression: "template.isSelected",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(template.name) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }