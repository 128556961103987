<template>
  <div>
    <b-modal
      id="export-templates-modal"
      title="Export"
      centered
    >
      <b-card no-body>
        <b-tabs
          pills
          card
        >
          <b-tab
            title="Download"
            active
          >
            <div class="form-row">
              <div class="col-md-2">
                <label>Saved settings</label>
              </div>

              <div class="col-md-10 form-group">
                <Multiselect
                  v-model="selectedDownloadSettings"
                  :options="downloadSettingsOptions"
                  placeholder="Select settings"
                  track-by="value"
                  label="text"
                  :multiple="false"
                  @select="applySavedSettings"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="col-md-2">
                <label>Format</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model="selectedFormat"
                  :options="formats"
                  class="form-control"
                  @change="setSavedSettingsToNull"
                />
              </div>
            </div>
            <template v-if="selectedFormat === 'pptx'">
              <div class="form-row">
                <div class="col-md-2">
                  <label>PPTX output type</label>
                </div>
                <div class="col-md-10 form-group">
                  <b-form-select
                    v-model="pptxIncludeFonts"
                    :options="pptxOutputTypes"
                    class="form-control"
                    @change="setSavedSettingsToNull"
                  />
                </div>
              </div>
            </template>
            <template v-if="selectedFormat === 'gif' || selectedFormat === 'pdf'">
              <div class="form-row container-downloadable-groups">
                <div class="col-md-2">
                  <label>Group</label>
                </div>
                <div class="col-md-10 form-group">
                  <MultipleSelectJquery
                    v-model="selectedGroupInstances"
                    class="export-pdf-groups-dropdown"
                    multiple
                    :data="groupsInstances"
                  />
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf'"
                class="form-row"
              >
                <div class="col-md-2" />
                <div class="col-md-10 form-group">
                  <label>
                    <input
                      v-model="createOneFilePerGroup"
                      type="checkbox"
                      @change="setSavedSettingsToNull"
                    >
                    Create one file for each group
                  </label>
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf'"
                class="form-row"
              >
                <div class="col-md-2" />
                <div class="col-md-10 form-group">
                  <label>
                    <input
                      v-model="createPageForEachTemplate"
                      type="checkbox"
                      @change="setSavedSettingsToNull"
                    >
                    Create one page for each template in the group
                  </label>
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf' && selectedGroupInstances.length == 1"
                class="form-row"
              >
                <div class="col-md-2" />
                <div class="col-md-10 form-group">
                  <label>
                    <input
                      v-model="getDirectLinktoFile"
                      type="checkbox"
                      @change="setSavedSettingsToNull"
                    >
                    Get direct link to the file
                  </label>
                </div>
              </div>

              <div
                v-if="selectedFormat === 'pdf'"
                class="form-row -groups"
              >
                <div class="col-md-2">
                  <label>Save to folder</label>
                </div>
                <div class="col-md-10 form-group">
                  <FolderTreeSelect
                    ref="selectedFolderInputForPDF"
                    v-model="selectedFolder"
                    :items="folders"
                    @input="setSavedSettingsToNull"
                  />
                </div>
              </div>
              <div class="form-row container-downloadable-single-template">
                <div class="col-md-2">
                  <label>Single Templates</label>
                </div>
                <div class="col-md-10 form-group">
                  <MultipleSelectJquery
                    v-model="selectedSingleInstances"
                    multiple
                    :data="singleInstances"
                    @change="setSavedSettingsToNull"
                  />
                </div>
              </div>
            </template>

            <template v-else>
              <div class="form-row container-downloadable-templates">
                <div class="col-md-2">
                  <label>Download</label>
                </div>
                <div class="col-md-10 form-group">
                  <Multiselect
                    v-model="selectedInstances"
                    :options="instancesOption"
                    track-by="instance_id"
                    label="name"
                    :loading="isTemplatesLoading"
                    placeholder="Select instances..."
                    :close-on-select="false"
                    multiple
                    @select="setSavedSettingsToNull"
                    @remove="setSavedSettingsToNull"
                  />
                </div>
              </div>
              <div
                v-if="selectedFormat === 'pptx'"
                class="form-row -groups"
              >
                <div class="col-md-2">
                  <label>Save to folder</label>
                </div>
                <div class="col-md-10 form-group">
                  <FolderTreeSelect
                    ref="selectedFolderInputForPPTX"
                    v-model="selectedFolder"
                    :items="folders"
                    @input="setSavedSettingsToNull"
                  />
                </div>
              </div>
            </template>
            <div
              v-if="['pptx'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2" />
              <div class="col-md-10">
                <label>
                  <input
                    v-model="downloadAsOneFile"
                    type="checkbox"
                    @change="setSavedSettingsToNull"
                  >
                  Download as one file
                </label>
              </div>
            </div>

            <div
              v-if="['psd', 'jpg_from_psd', 'psd_and_jpg', 'pptx'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2" />
              <div class="col-md-10 form-group">
                <label>
                  <input
                    v-model="downloadInBackground"
                    type="checkbox"
                    @change="setSavedSettingsToNull"
                  >
                  Download in background
                </label>
              </div>
            </div>

            <div
              v-if="['psd', 'jpg', 'png', 'jpg_from_psd'].includes(selectedFormat)"
              class="form-row container-platforms"
            >
              <div class="col-md-2">
                <label>DPI</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model.number="selectedDpi"
                  class="form-control"
                  @change="updateDpi(selectedDpi)"
                >
                  <option value="72">
                    72
                  </option>
                  <option value="75">
                    75
                  </option>
                  <option value="100">
                    100
                  </option>
                  <option value="150">
                    150
                  </option>
                  <option value="200">
                    200
                  </option>
                  <option value="300">
                    300
                  </option>
                  <option value="0">
                    Custom
                  </option>
                </b-form-select>
                <div class="my-2" />
                <b-form-input
                  v-if="!selectedDpi"
                  v-model.number="dpi"
                  type="number"
                  @change="setSavedSettingsToNull"
                />
              </div>
            </div>
            <div
              v-show="['jpg', 'png'].includes(selectedFormat)"
              class="form-row"
            >
              <div class="col-md-2">
                <label>Size</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model.number="selectedSize"
                  class="form-control"
                  @change="setSavedSettingsToNull"
                >
                  <option :value="SIZE_OPTIONS_TYPE.KEEP_ORIGINAL">
                    Keep original size
                  </option>
                  <option :value="SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE">
                    Scale down by %
                  </option>
                  <option
                    v-if="selectedInstances.length === 1"
                    :value="SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS"
                  >
                    Scale down to specific dimensions
                  </option>
                </b-form-select>
                <div v-if="selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE">
                  <div class="my-2" />
                  <b-form-input
                    v-model="size.percentage"
                    type="number"
                    @change="setSavedSettingsToNull"
                  />
                </div>
                <div
                  v-if="selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS"
                  class="dimensions-container"
                >
                  <div class="my-2" />
                  <b-form-input
                    v-model="size.dimensions.width"
                    type="number"
                    @change="handleWidthChange"
                  />
                  x
                  <b-form-input
                    v-model="size.dimensions.height"
                    type="number"
                    @change="handleHeightChange"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="selectedFormat === 'html'"
              class="form-row container-platforms"
            >
              <div class="col-md-2">
                <label>Platform</label>
              </div>
              <div class="col-md-10 form-group">
                <b-form-select
                  v-model="selectedPlatform"
                  class="form-control"
                  @change="setSavedSettingsToNull"
                >
                  <option value="">
                    None
                  </option>
                  <option value="ttd">
                    The Trade Desk (TTD)
                  </option>
                </b-form-select>
              </div>
            </div>

            <div v-if="checkTestMode()">
              <div class="form-row">
                <div class="col-md-2">
                  <label>Test mode
                    <i
                      class="cursor-pointer glyphicon glyphicon-refresh reset-text-position"
                      title="Get data to json"
                      @click="setJsonText"
                    />
                  </label>
                </div>
                <div class="col-md-10 form-group">
                  <textarea
                    v-model="jsonText"
                    rows="15"
                    cols="80"
                    class="json-editor"
                    @input="validateJSON"
                  />
                  <p
                    v-if="errorMessage"
                    class="error-message"
                  >
                    {{ errorMessage }}
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="compressionCanBeApplied"
              class="form-row"
            >
              <div class="col-md-2" />

              <div class="col-md-10 form-row align-items-center">
                <label class="col-md-6">
                  <input
                    v-model="compressToMaxFileSize"
                    type="checkbox"
                    @change="setSavedSettingsToNull"
                  >

                  Compress to Max File Size
                </label>

                <div
                  v-if="compressToMaxFileSize"
                  class="col-md-6 form-row align-items-center"
                >
                  <b-form-input
                    v-model="compressToMaxFileSizeValue"
                    class="w-75"
                    type="number"
                    placeholder="Max file size"
                    @change="setSavedSettingsToNull"
                  />
                  <span class="ml-2">KB</span>
                </div>
              </div>
            </div>

            <div class="form-row justify-content-end mt-3">
              <a
                href=""
                @click="showSaveSettingsModal"
              >
                Save settings
              </a>
            </div>
          </b-tab>

          <b-tab title="Saved settings">
            <SavedDownloadSettingsTab
              ref="savedDownloadSettingsTab"
              :is-master-admin="isMasterAdmin"
              @settings-updated="onSettingsUpdated"
            />
          </b-tab>
        </b-tabs>
      </b-card>

      <template #modal-footer>
        <div
          v-if="showBar && isSaving"
          class="col-12"
        >
          <ProgressBarComponent
            :current="current"
            :total="total"
          />
        </div>
        <b-button
          variant="primary"
          :disabled="isSaving"
          @click="onSave"
        >
          <div v-if="isSaving">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Exporting...
          </div>
          <span v-else>Export</span>
        </b-button>
        <b-button
          variant="secondary"
          @click="onClose"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <SaveDownloadSettingsModal
      ref="saveDownloadSettingsModal"
      :is-master-admin="isMasterAdmin"
      @saved="onSettingSaved"
    />
  </div>
</template>

<script>
import { exportTemplatesEventBroker, EVENTS } from '../../../group/modules/export-templates/event-broker';
import MultipleSelectJquery from '../../common/atoms/MultipleSelectJquery.vue';
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {bus} from '../../../app'
import {SIZE_OPTIONS_TYPE} from "@frontend/constants/layout-export";
import layoutApi from "@frontend/services/api/layout";
import FolderTreeSelect from "@frontend/components/common/atoms/FolderTreeSelect/FolderTreeSelect.vue";
import Pusher from "pusher-js";
import ProgressBarComponent from "@frontend/components/ProgressBarComponent.vue";
import SaveDownloadSettingsModal from "@frontend/components/modules/layouts-page/modals/SaveDownloadSettingsModal.vue";
import SavedDownloadSettingsTab from "@frontend/components/modules/layouts-page/SavedDownloadSettingsTab.vue";
import savedDowloadSettingsAPI from "@frontend/services/api/layout-saved-download-settings.js";

const initSize = {
  percentage: 100,
  dimensions: {
    width: null,
    height: null,
  }
};


export default {
  name: 'ExportTemplatesModal',
  components: {
    FolderTreeSelect,
    MultipleSelectJquery,
    Multiselect,
    ProgressBarComponent,
    SaveDownloadSettingsModal,
    SavedDownloadSettingsTab,
  },
  data() {
    return {
      isSaving: false,
      formats: [
        {
          text: 'JPG',
          value: 'jpg'
        },
        {
          text: 'PNG',
          value: 'png'
        },
        {
          text: 'GIF',
          value: 'gif'
        },
        {
          text: 'HTML',
          value: 'html'
        },
        {
          text: 'PDF',
          value: 'pdf'
        },
        {
          text: 'PPTX',
          value: 'pptx'
        },
        {
          text: 'Feed',
          value: 'feed'
        },
        {
          text: 'PSD',
          value: 'psd'
        },
        {
          text: 'TIFF',
          value: 'tiff'
        },
        {
          text: 'JPG from PSD',
          value: 'jpg_from_psd'
        },
        {
          text: 'PSD and JPG',
          value: 'psd_and_jpg'
        },
      ],
      dpi: 72,
      selectedDpi: 72,
      selectedFormat: 'jpg',
      selectedPlatform: '',
      selectedSize: SIZE_OPTIONS_TYPE.KEEP_ORIGINAL,
      size: {...initSize},
      templateDimensions: {
        width: null,
        height: null,
      },
      isTemplatesLoading: false,
      templateAspectRatio: 1,
      selectedInstances: [],
      selectedGroupInstances: [],
      selectedSingleInstances: [],
      selectedFolder: null,
      instances: [],
      groupsInstances: [],
      singleInstances: [],
      folders: [],
      selectAllText : 'Select All',
      createPageForEachTemplate: true,
      createOneFilePerGroup: true,
      getDirectLinktoFile: false,
      downloadInBackground: true,
      downloadAsOneFile: false,
      singleInstanceId: null,
      groupInstancesMap: [],
      pptxOutputTypes: [
        {
          text: 'PPTX only',
          value: false
        },
        {
          text: 'PPTX and fonts in zip file',
          value: true
        }
      ],
      pptxIncludeFonts: false,
      layoutId: null,
      groupsTemplates: null,
      layoutOptions: null,
      errorMessage: null,
      jsonText: `{}`,
      showBar: false,
      total: 0,
      current: 0,
      selectedDownloadSettings: null,
      downloadSettingsOptions: [],
      isMasterAdmin: false,
      compressToMaxFileSize: false,
      compressToMaxFileSizeValue: null,
    };
  },
  computed:{
    SIZE_OPTIONS_TYPE() {
      return SIZE_OPTIONS_TYPE
    },
      instancesOption() {
          const selectAllOption = {instance_id: -1, name: this.selectAllText};
          return [selectAllOption, ...this.instances];
      },
    compressionCanBeApplied() {
      return ['jpg', 'jpg_from_psd'].includes(this.selectedFormat);
    }
  },
  watch : {
      selectedFormat() {
        this.selectedSize = SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;
        this.size = {...initSize};
      },
      selectedInstances(newSelectedInstances) {
          const allSelected = newSelectedInstances.some(option => option.instance_id === -1);
          if (allSelected) {
              this.toggleSelectAllText();
          }

          if (!newSelectedInstances.length ||
            (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS &&
              (newSelectedInstances.length > 1 || newSelectedInstances.length === 0)
            )) {
            this.selectedSize = SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;

            this.templateDimensions = {
              width: null,
              height: null,
            }
            
            this.size.dimensions = {
              width: null,
              height: null
            }
          } else {
            const preview = previews.find(preview => preview.id === newSelectedInstances[0].instance_id);

            if (preview) {
              this.templateDimensions = {
                width: preview.template.width,
                height: preview.template.height
              };

              this.templateAspectRatio = preview.template.width / preview.template.height;
            }
          }
      },
    selectedSize() {
        this.size.percentage = 100;
        
        if (this.selectedInstances.length === 1) {
          this.size.dimensions = {
            width: this.templateDimensions.width,
            height: this.templateDimensions.height
          }
        } else {
          this.size.dimensions = {
            width: null,
            height: null
          }
        }
    },
  },
  mounted() {
    exportTemplatesEventBroker.on(EVENTS.INIT, this.init.bind(this));
    exportTemplatesEventBroker.on(EVENTS.SHOW, this.onShow.bind(this));
    exportTemplatesEventBroker.on(EVENTS.CLOSE, this.onClose.bind(this));
    exportTemplatesEventBroker.on(EVENTS.FINISH, this.onFinish.bind(this));
    bus.$on('singleInstanceId', (singleInstanceId) => {
      if(singleInstanceId) {
        this.selectedInstances = this.instancesOption.filter(option => option.instance_id == singleInstanceId); 
      }
    });

    const user_id = $('meta[name="userId"]').attr("content");
    let pusher = new Pusher(process.env.MIX_PUSHER_APP_KEY, {
      cluster: process.env.MIX_PUSHER_APP_CLUSTER
    });

    let channel = pusher.subscribe('export.'+user_id);
    channel.bind('fileAddToZip', (data) => {
      this.current++;
    });
  },
  methods: {
    checkTestMode() {
      return this.layoutOptions?.test_mode === 'on';
    },
    validateJSON() {
      try {
        JSON.parse(this.jsonText);
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage = "Invalid JSON: " + error.message;
      }
    },
    init({ layoutId, groupsTemplates, folders, layout_options, isMasterAdmin }) {
      this.layoutId = layoutId;
      this.groupsTemplates = groupsTemplates;
      this.folders = folders;
      this.layoutOptions = layout_options;
      this.isMasterAdmin = isMasterAdmin;
      this.compressToMaxFileSize = this.layoutOptions.compress_max_file_size || false;
      this.compressToMaxFileSizeValue = this.layoutOptions.compress_max_file_size_value || null;

      this.prepareModalData();
    },
    async prepareModalData() {
      this.isTemplatesLoading = true;

      try {
        const { data: templates } = await layoutApi.getLayoutTemplates(this.layoutId, { simplified: true });
        this.instances = templates.map(template => ({ instance_id: template.instance_id, name: template.name }));
      } catch (error) {
        console.error(error);
      } finally {
        this.isTemplatesLoading = false;
      }

      if (this.groupsTemplates.length) {
        this.groupsInstances = this.groupsTemplates.map((group, groupIndex) => ({
          type: 'optgroup',
          label: `Group ${groupIndex + 1}`,
          children: this.instances
            .filter(instance => group.instancesId.includes(instance.instance_id))
            .map(instance => ({
              text: instance.name,
              value: instance.instance_id,
              classes: 'export-pdf-groups-dropdown__option'
            }))
        }));

        this.groupInstancesMap = this.groupsTemplates.map((group, groupIndex) => ({
          group: groupIndex,
          instances: [...group.instancesId]
        }));
      }

      this.singleInstances = this.instances.filter(instance => {
        return !this.groupsTemplates.find((group) => {
          return group.instancesId.includes(instance.instance_id);
        })
      }).map(instance => {
        return {
          value: instance.instance_id,
          text: instance.name
        }
      });
    },
    onShow() {
      this.prepareModalData();
      this.$bvModal.show('export-templates-modal');
      this.loadSavedSettingsDropdown();
      this.$nextTick(() => this.$refs.savedDownloadSettingsTab.load());
    },
    onClose() {
      this.isSaving = false;
      this.showBar = false;
      this.$bvModal.hide('export-templates-modal');
    },
    onFinish(){
      this.isSaving = false;
      this.showBar = false;
    },
    validateSaveRequest() {
      if (this.selectedFormat === 'pdf' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group or template from the list");
        return false;
      }

      if (this.selectedFormat === 'gif' && (!this.selectedGroupInstances.length && !this.selectedSingleInstances.length)) {
        window.toastr.error("Please select at least one group from the list");
        return false;
      }

      if (this.selectedFormat !== 'gif' && this.selectedFormat !== 'pdf' && !this.selectedInstances.length){
        window.toastr.error("Please select at least one template from the list");
        return false;
      }

      return true;
    },
    onSave() {
      if (! this.validateSaveRequest()) {
        return;
      }

      if (this.selectedFormat !== 'gif' && this.selectedFormat !== 'pdf' && !this.downloadInBackground) {
        this.showBar = true;
        this.total = this.selectedInstances.length;
        this.current = 0;
      }

      this.isSaving = true;

      const data = this.getDataToEvent();
      exportTemplatesEventBroker.fire(EVENTS.EXPORT, data)
    },
    getDataToEvent() {
      if (this.checkTestMode()) {
        return JSON.parse(this.jsonText);
      }

      const data = {
        exportFormat: this.selectedFormat,
        selectedInstances: this.selectedInstances,
        selectedGroupInstances: this.getSelectedTemplateGroups(),
        selectedSingleInstances: this.selectedSingleInstances,
        selectedHTMLPlatform: this.selectedPlatform,
        dpi: this.dpi,
        createPageForEachTemplate: this.createPageForEachTemplate,
        getDirectLinktoFile: this.getDirectLinktoFile,
        selectedFolder: this.selectedFolder,
        createOneFilePerGroup: this.createOneFilePerGroup,
        downloadInBackground: this.downloadInBackground,
        downloadAsOneFile: this.downloadAsOneFile,
        sizeOptions: {
          type: this.selectedSize,
          scalePercentage: this.size.percentage,
          dimensions: this.size.dimensions,
        },
        pptxIncludeFonts: this.pptxIncludeFonts,
      };

      if (this.compressionCanBeApplied) {
        data.compressionOptions = {
          compress: this.compressToMaxFileSize,
          compressValue: this.compressToMaxFileSizeValue,
        };
      }

      return data;
    },
    selectAllOptions(selected) {
        if (selected) {
            this.selectedInstances = this.instances.map(instance => instance.instance_id);
        } else {
            this.selectedInstances = [];
        }
    },
    toggleSelectAllText() {
        if (this.selectAllText === 'Select All') {
            this.selectedInstances = this.instancesOption.filter(option => option.instance_id !== -1);
            this.selectAllText = 'Unselect All';
        } else {
            this.selectedInstances = [];
            this.selectAllText = 'Select All';
        }
    },
    handleSelection(selectedFolder) {
      this.selectedFolder = selectedFolder.id;
    },
    handleWidthChange(newWidth) {
      this.size.dimensions.width = +newWidth;
      this.size.dimensions.height = Math.round(newWidth / this.templateAspectRatio);
      this.setSavedSettingsToNull();
    },
    handleHeightChange(newHeight) {
      this.size.dimensions.height = +newHeight;
      this.size.dimensions.width = Math.round(newHeight * this.templateAspectRatio);
      this.setSavedSettingsToNull();
    },
    getSelectedTemplateGroups() {
      const selectedGroups = new Set();
      
      this.selectedGroupInstances.forEach(instance => {
        const group = this.groupInstancesMap.find(groupInstance => groupInstance.instances.includes(instance));

        if (group) {
          selectedGroups.add(group.group);
        }
      });
      
      return Array.from(selectedGroups);
    },
    setJsonText() {
      let data = {
        exportFormat: this.selectedFormat,
        selectedInstances: this.selectedInstances,
        selectedGroupInstances: this.getSelectedTemplateGroups(),
        selectedSingleInstances: this.selectedSingleInstances,
        selectedHTMLPlatform: this.selectedPlatform,
        dpi: this.dpi,
        createPageForEachTemplate: this.createPageForEachTemplate,
        getDirectLinktoFile: this.getDirectLinktoFile,
        selectedFolder: this.selectedFolder,
        createOneFilePerGroup: this.createOneFilePerGroup,
        downloadInBackground: this.downloadInBackground,
        downloadAsOneFile: this.downloadAsOneFile,
        sizeOptions: {
          type: this.selectedSize,
          scalePercentage: this.size.percentage,
          dimensions: this.size.dimensions,
        },
        pptxIncludeFonts: this.pptxIncludeFonts,
      };

      if (this.compressionCanBeApplied) {
        data.compressionOptions = {
          compress: this.compressToMaxFileSize,
          compressValue: this.compressToMaxFileSizeValue,
        };
      }

      this.jsonText = JSON.stringify(data, null, 2);
    },
    showSaveSettingsModal(event) {
      event.preventDefault();

      this.$refs.saveDownloadSettingsModal.show(this.getDataToEvent());
    },
    async loadSavedSettingsDropdown() {
      const { data: options } = await savedDowloadSettingsAPI.index({});
      this.downloadSettingsOptions = options.map(option => ({ text: option.name, value: option }));
    },
    onSettingsUpdated() {
      this.loadSavedSettingsDropdown();
    },
    onSettingSaved() {
      this.loadSavedSettingsDropdown();
      this.$refs.savedDownloadSettingsTab.load();
    },
    applySavedSettings(selectedOpition, id) {
      const settingInstance = selectedOpition.value || null;

      if (!settingInstance) {
        return;
      }

      const loadingOverlay = document.querySelector('.loadingOverlay');
      const textItem = loadingOverlay.querySelector('.loadingOverlay-text');
      const oldText = textItem.textContent;

      textItem.textContent = 'Applying saved settings...';
      loadingOverlay.style.display = 'block';

      const settings = settingInstance.settings;
      try {
        this.dpi = settings.dpi;
        this.selectedDpi = settings.dpi;
        this.selectedFormat = settings.exportFormat;
        this.selectedFolder = settings.selectedFolder;
        this.selectedPlatform = settings.selectedHTMLPlatform || '';
        this.createPageForEachTemplate = settings.createPageForEachTemplate;
        this.createOneFilePerGroup = settings.createOneFilePerGroup;
        this.downloadInBackground = settings.downloadInBackground;
        this.downloadAsOneFile = settings.downloadAsOneFile;
        this.pptxIncludeFonts = settings.pptxIncludeFonts;
        this.getDirectLinktoFile = settings.getDirectLinktoFile;

        // add selected instances from settings if they present in layout and no instances were selected before
        if (
          settings.selectedInstances
          && settings.selectedInstances.length
          && !this.selectedInstances.length
        ) {
          settings.selectedInstances.forEach(settingSelectedInstance => {
            if (this.instances.find(instance => instance.instance_id === settingSelectedInstance.instance_id)) {
              this.selectedInstances.push(settingSelectedInstance);
            }
          });
        }

        // same as above
        if (
          settings.selectedSingleInstances
          && settings.selectedSingleInstances.length
          && !this.selectedSingleInstances.length
        ) {
          settings.selectedSingleInstances.forEach(settingSelectedInstance => {
            if (this.singleInstances.find(instance => instance.value === settingSelectedInstance)) {
              this.selectedSingleInstances.push(settingSelectedInstance);
            }
          });
        }

        // selected size scale down by dimensions applyable only for single instance
        if (settings.sizeOptions.type === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS) {
          this.selectedSize = this.selectedInstances.length === 1
            ? settings.sizeOptions.type
            : SIZE_OPTIONS_TYPE.KEEP_ORIGINAL;
        } else {
          this.selectedSize = settings.sizeOptions.type;
        }

        this.$nextTick(() => {
          setTimeout(() => {
            if (this.selectedFolder) {
              if (this.selectedFormat === 'pdf') {
                this.$refs.selectedFolderInputForPDF.handleFolderChange(this.selectedFolder, true);
              } else if (this.selectedFormat === 'pptx') {
                this.$refs.selectedFolderInputForPPTX.handleFolderChange(this.selectedFolder, true);
              }
            }

            if (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE) {
              this.size.percentage = settings.sizeOptions.scalePercentage;
            } else if (this.selectedSize === SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS) {
              this.size.dimensions.width = settings.sizeOptions.dimensions.width;
              this.size.dimensions.height = settings.sizeOptions.dimensions.height;
            }
          }, 0);
        });

        if (this.compressionCanBeApplied) {
          this.compressToMaxFileSize = settings.compressionOptions.compress;
          this.compressToMaxFileSizeValue = settings.compressionOptions.compressValue;
        }
      } catch (error) {
        window.toastr.error('Failed to apply saved settings');
        console.error(error);
      } finally {
        textItem.textContent = oldText;
        loadingOverlay.style.display = 'none';
      }
    },
    setSavedSettingsToNull() {
      this.selectedDownloadSettings = null;
    },
    updateDpi(selectedDpi) {
      this.dpi = selectedDpi;
      this.setSavedSettingsToNull();
    }
  }
}
</script>
<style lang="scss">
@media (min-width: 576px) {
    #export-templates-modal .modal-dialog {
        width: 900px !important;
        max-width: 900px !important;
    }
}
.dimensions-container {
  input {
    display: inline-block;
    width: 30%;
  }
}

.container-downloadable-templates .multiselect__tags {
    max-height: 135px;
    overflow: scroll;
}

.export-pdf-groups-dropdown {
  &__option {
    pointer-events: none;
    
    input {
      display: none;
    }
  }
}
textarea.json-editor {
  width: 100%; /* Заполнит всю доступную ширину */
  height: 300px; /* Высота редактора */
  font-family: monospace; /* Шрифт, удобный для кода */
  padding: 10px; /* Внутренние отступы */
  border: 1px solid #ccc; /* Граница редактора */
  border-radius: 5px; /* Скругленные углы */
  box-sizing: border-box; /* Учет отступов и границ в размере */
  resize: vertical; /* Пользователь может изменять только высоту */
}

#export-templates-modal .modal-body {
  padding: 0 !important;

  .card {
    border: none !important;
    margin-bottom: 0 !important;

    .tabs {
      .card-header {
        background-color: transparent !important;
      }
    }
  }
}
</style>
