import { render, staticRenderFns } from "./ExportPageFooterTab.vue?vue&type=template&id=70e7a874&scoped=true"
import script from "./ExportPageFooterTab.vue?vue&type=script&lang=js"
export * from "./ExportPageFooterTab.vue?vue&type=script&lang=js"
import style0 from "./ExportPageFooterTab.vue?vue&type=style&index=0&id=70e7a874&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e7a874",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_testing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70e7a874')) {
      api.createRecord('70e7a874', component.options)
    } else {
      api.reload('70e7a874', component.options)
    }
    module.hot.accept("./ExportPageFooterTab.vue?vue&type=template&id=70e7a874&scoped=true", function () {
      api.rerender('70e7a874', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/Export/ExportPageFooterTab.vue"
export default component.exports