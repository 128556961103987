var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "export-templates-modal",
            title: "Export",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _vm.showBar && _vm.isSaving
                    ? _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("ProgressBarComponent", {
                            attrs: { current: _vm.current, total: _vm.total },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", disabled: _vm.isSaving },
                      on: { click: _vm.onSave },
                    },
                    [
                      _vm.isSaving
                        ? _c("div", [
                            _c("span", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status", "aria-hidden": "true" },
                            }),
                            _vm._v("\n          Exporting...\n        "),
                          ])
                        : _c("span", [_vm._v("Export")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.onClose },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-tabs",
                { attrs: { pills: "", card: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Download", active: "" } },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-2" }, [
                          _c("label", [_vm._v("Saved settings")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-10 form-group" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                options: _vm.downloadSettingsOptions,
                                placeholder: "Select settings",
                                "track-by": "value",
                                label: "text",
                                multiple: false,
                              },
                              on: { select: _vm.applySavedSettings },
                              model: {
                                value: _vm.selectedDownloadSettings,
                                callback: function ($$v) {
                                  _vm.selectedDownloadSettings = $$v
                                },
                                expression: "selectedDownloadSettings",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-2" }, [
                          _c("label", [_vm._v("Format")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-10 form-group" },
                          [
                            _c("b-form-select", {
                              staticClass: "form-control",
                              attrs: { options: _vm.formats },
                              on: { change: _vm.setSavedSettingsToNull },
                              model: {
                                value: _vm.selectedFormat,
                                callback: function ($$v) {
                                  _vm.selectedFormat = $$v
                                },
                                expression: "selectedFormat",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.selectedFormat === "pptx"
                        ? [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", [_vm._v("PPTX output type")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-10 form-group" },
                                [
                                  _c("b-form-select", {
                                    staticClass: "form-control",
                                    attrs: { options: _vm.pptxOutputTypes },
                                    on: { change: _vm.setSavedSettingsToNull },
                                    model: {
                                      value: _vm.pptxIncludeFonts,
                                      callback: function ($$v) {
                                        _vm.pptxIncludeFonts = $$v
                                      },
                                      expression: "pptxIncludeFonts",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedFormat === "gif" ||
                      _vm.selectedFormat === "pdf"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-row container-downloadable-groups",
                              },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", [_vm._v("Group")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-10 form-group" },
                                  [
                                    _c("MultipleSelectJquery", {
                                      staticClass: "export-pdf-groups-dropdown",
                                      attrs: {
                                        multiple: "",
                                        data: _vm.groupsInstances,
                                      },
                                      model: {
                                        value: _vm.selectedGroupInstances,
                                        callback: function ($$v) {
                                          _vm.selectedGroupInstances = $$v
                                        },
                                        expression: "selectedGroupInstances",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.selectedFormat === "pdf"
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c("div", { staticClass: "col-md-2" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-10 form-group" },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.createOneFilePerGroup,
                                              expression:
                                                "createOneFilePerGroup",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.createOneFilePerGroup
                                            )
                                              ? _vm._i(
                                                  _vm.createOneFilePerGroup,
                                                  null
                                                ) > -1
                                              : _vm.createOneFilePerGroup,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.createOneFilePerGroup,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.createOneFilePerGroup =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.createOneFilePerGroup =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.createOneFilePerGroup =
                                                    $$c
                                                }
                                              },
                                              _vm.setSavedSettingsToNull,
                                            ],
                                          },
                                        }),
                                        _vm._v(
                                          "\n                  Create one file for each group\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedFormat === "pdf"
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c("div", { staticClass: "col-md-2" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-10 form-group" },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.createPageForEachTemplate,
                                              expression:
                                                "createPageForEachTemplate",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.createPageForEachTemplate
                                            )
                                              ? _vm._i(
                                                  _vm.createPageForEachTemplate,
                                                  null
                                                ) > -1
                                              : _vm.createPageForEachTemplate,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.createPageForEachTemplate,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.createPageForEachTemplate =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.createPageForEachTemplate =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.createPageForEachTemplate =
                                                    $$c
                                                }
                                              },
                                              _vm.setSavedSettingsToNull,
                                            ],
                                          },
                                        }),
                                        _vm._v(
                                          "\n                  Create one page for each template in the group\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedFormat === "pdf" &&
                            _vm.selectedGroupInstances.length == 1
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c("div", { staticClass: "col-md-2" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-10 form-group" },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.getDirectLinktoFile,
                                              expression: "getDirectLinktoFile",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.getDirectLinktoFile
                                            )
                                              ? _vm._i(
                                                  _vm.getDirectLinktoFile,
                                                  null
                                                ) > -1
                                              : _vm.getDirectLinktoFile,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.getDirectLinktoFile,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.getDirectLinktoFile =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.getDirectLinktoFile =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.getDirectLinktoFile = $$c
                                                }
                                              },
                                              _vm.setSavedSettingsToNull,
                                            ],
                                          },
                                        }),
                                        _vm._v(
                                          "\n                  Get direct link to the file\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedFormat === "pdf"
                              ? _c("div", { staticClass: "form-row -groups" }, [
                                  _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", [_vm._v("Save to folder")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-10 form-group" },
                                    [
                                      _c("FolderTreeSelect", {
                                        ref: "selectedFolderInputForPDF",
                                        attrs: { items: _vm.folders },
                                        on: {
                                          input: _vm.setSavedSettingsToNull,
                                        },
                                        model: {
                                          value: _vm.selectedFolder,
                                          callback: function ($$v) {
                                            _vm.selectedFolder = $$v
                                          },
                                          expression: "selectedFolder",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-row container-downloadable-single-template",
                              },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", [_vm._v("Single Templates")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-10 form-group" },
                                  [
                                    _c("MultipleSelectJquery", {
                                      attrs: {
                                        multiple: "",
                                        data: _vm.singleInstances,
                                      },
                                      on: {
                                        change: _vm.setSavedSettingsToNull,
                                      },
                                      model: {
                                        value: _vm.selectedSingleInstances,
                                        callback: function ($$v) {
                                          _vm.selectedSingleInstances = $$v
                                        },
                                        expression: "selectedSingleInstances",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-row container-downloadable-templates",
                              },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", [_vm._v("Download")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-10 form-group" },
                                  [
                                    _c("Multiselect", {
                                      attrs: {
                                        options: _vm.instancesOption,
                                        "track-by": "instance_id",
                                        label: "name",
                                        loading: _vm.isTemplatesLoading,
                                        placeholder: "Select instances...",
                                        "close-on-select": false,
                                        multiple: "",
                                      },
                                      on: {
                                        select: _vm.setSavedSettingsToNull,
                                        remove: _vm.setSavedSettingsToNull,
                                      },
                                      model: {
                                        value: _vm.selectedInstances,
                                        callback: function ($$v) {
                                          _vm.selectedInstances = $$v
                                        },
                                        expression: "selectedInstances",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.selectedFormat === "pptx"
                              ? _c("div", { staticClass: "form-row -groups" }, [
                                  _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", [_vm._v("Save to folder")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-10 form-group" },
                                    [
                                      _c("FolderTreeSelect", {
                                        ref: "selectedFolderInputForPPTX",
                                        attrs: { items: _vm.folders },
                                        on: {
                                          input: _vm.setSavedSettingsToNull,
                                        },
                                        model: {
                                          value: _vm.selectedFolder,
                                          callback: function ($$v) {
                                            _vm.selectedFolder = $$v
                                          },
                                          expression: "selectedFolder",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                      _vm._v(" "),
                      ["pptx"].includes(_vm.selectedFormat)
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-2" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-10" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.downloadAsOneFile,
                                      expression: "downloadAsOneFile",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.downloadAsOneFile
                                    )
                                      ? _vm._i(_vm.downloadAsOneFile, null) > -1
                                      : _vm.downloadAsOneFile,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.downloadAsOneFile,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.downloadAsOneFile =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.downloadAsOneFile = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.downloadAsOneFile = $$c
                                        }
                                      },
                                      _vm.setSavedSettingsToNull,
                                    ],
                                  },
                                }),
                                _vm._v(
                                  "\n                Download as one file\n              "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      ["psd", "jpg_from_psd", "psd_and_jpg", "pptx"].includes(
                        _vm.selectedFormat
                      )
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-2" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-10 form-group" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.downloadInBackground,
                                      expression: "downloadInBackground",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.downloadInBackground
                                    )
                                      ? _vm._i(_vm.downloadInBackground, null) >
                                        -1
                                      : _vm.downloadInBackground,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.downloadInBackground,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.downloadInBackground =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.downloadInBackground = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.downloadInBackground = $$c
                                        }
                                      },
                                      _vm.setSavedSettingsToNull,
                                    ],
                                  },
                                }),
                                _vm._v(
                                  "\n                Download in background\n              "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      ["psd", "jpg", "png", "jpg_from_psd"].includes(
                        _vm.selectedFormat
                      )
                        ? _c(
                            "div",
                            { staticClass: "form-row container-platforms" },
                            [
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", [_vm._v("DPI")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-10 form-group" },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      staticClass: "form-control",
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateDpi(_vm.selectedDpi)
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedDpi,
                                        callback: function ($$v) {
                                          _vm.selectedDpi = _vm._n($$v)
                                        },
                                        expression: "selectedDpi",
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "72" } }, [
                                        _vm._v(
                                          "\n                  72\n                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "75" } }, [
                                        _vm._v(
                                          "\n                  75\n                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "100" } },
                                        [
                                          _vm._v(
                                            "\n                  100\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "150" } },
                                        [
                                          _vm._v(
                                            "\n                  150\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "200" } },
                                        [
                                          _vm._v(
                                            "\n                  200\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "300" } },
                                        [
                                          _vm._v(
                                            "\n                  300\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "0" } }, [
                                        _vm._v(
                                          "\n                  Custom\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "my-2" }),
                                  _vm._v(" "),
                                  !_vm.selectedDpi
                                    ? _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: {
                                          change: _vm.setSavedSettingsToNull,
                                        },
                                        model: {
                                          value: _vm.dpi,
                                          callback: function ($$v) {
                                            _vm.dpi = _vm._n($$v)
                                          },
                                          expression: "dpi",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: ["jpg", "png"].includes(
                                _vm.selectedFormat
                              ),
                              expression:
                                "['jpg', 'png'].includes(selectedFormat)",
                            },
                          ],
                          staticClass: "form-row",
                        },
                        [
                          _c("div", { staticClass: "col-md-2" }, [
                            _c("label", [_vm._v("Size")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-10 form-group" },
                            [
                              _c(
                                "b-form-select",
                                {
                                  staticClass: "form-control",
                                  on: { change: _vm.setSavedSettingsToNull },
                                  model: {
                                    value: _vm.selectedSize,
                                    callback: function ($$v) {
                                      _vm.selectedSize = _vm._n($$v)
                                    },
                                    expression: "selectedSize",
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value:
                                          _vm.SIZE_OPTIONS_TYPE.KEEP_ORIGINAL,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Keep original size\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value:
                                          _vm.SIZE_OPTIONS_TYPE
                                            .SCALE_DOWN_BY_PERCENTAGE,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Scale down by %\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.selectedInstances.length === 1
                                    ? _c(
                                        "option",
                                        {
                                          domProps: {
                                            value:
                                              _vm.SIZE_OPTIONS_TYPE
                                                .SCALE_DOWN_BY_DIMENSIONS,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Scale down to specific dimensions\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.selectedSize ===
                              _vm.SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_PERCENTAGE
                                ? _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "my-2" }),
                                      _vm._v(" "),
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: {
                                          change: _vm.setSavedSettingsToNull,
                                        },
                                        model: {
                                          value: _vm.size.percentage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.size,
                                              "percentage",
                                              $$v
                                            )
                                          },
                                          expression: "size.percentage",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.selectedSize ===
                              _vm.SIZE_OPTIONS_TYPE.SCALE_DOWN_BY_DIMENSIONS
                                ? _c(
                                    "div",
                                    { staticClass: "dimensions-container" },
                                    [
                                      _c("div", { staticClass: "my-2" }),
                                      _vm._v(" "),
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: { change: _vm.handleWidthChange },
                                        model: {
                                          value: _vm.size.dimensions.width,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.size.dimensions,
                                              "width",
                                              $$v
                                            )
                                          },
                                          expression: "size.dimensions.width",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                x\n                "
                                      ),
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        on: { change: _vm.handleHeightChange },
                                        model: {
                                          value: _vm.size.dimensions.height,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.size.dimensions,
                                              "height",
                                              $$v
                                            )
                                          },
                                          expression: "size.dimensions.height",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.selectedFormat === "html"
                        ? _c(
                            "div",
                            { staticClass: "form-row container-platforms" },
                            [
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", [_vm._v("Platform")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-10 form-group" },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      staticClass: "form-control",
                                      on: {
                                        change: _vm.setSavedSettingsToNull,
                                      },
                                      model: {
                                        value: _vm.selectedPlatform,
                                        callback: function ($$v) {
                                          _vm.selectedPlatform = $$v
                                        },
                                        expression: "selectedPlatform",
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          "\n                  None\n                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "ttd" } },
                                        [
                                          _vm._v(
                                            "\n                  The Trade Desk (TTD)\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.checkTestMode()
                        ? _c("div", [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", [
                                  _vm._v("Test mode\n                  "),
                                  _c("i", {
                                    staticClass:
                                      "cursor-pointer glyphicon glyphicon-refresh reset-text-position",
                                    attrs: { title: "Get data to json" },
                                    on: { click: _vm.setJsonText },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-10 form-group" },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.jsonText,
                                        expression: "jsonText",
                                      },
                                    ],
                                    staticClass: "json-editor",
                                    attrs: { rows: "15", cols: "80" },
                                    domProps: { value: _vm.jsonText },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.jsonText = $event.target.value
                                        },
                                        _vm.validateJSON,
                                      ],
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.errorMessage
                                    ? _c(
                                        "p",
                                        { staticClass: "error-message" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.errorMessage) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.compressionCanBeApplied
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-md-2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-10 form-row align-items-center",
                              },
                              [
                                _c("label", { staticClass: "col-md-6" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.compressToMaxFileSize,
                                        expression: "compressToMaxFileSize",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.compressToMaxFileSize
                                      )
                                        ? _vm._i(
                                            _vm.compressToMaxFileSize,
                                            null
                                          ) > -1
                                        : _vm.compressToMaxFileSize,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = _vm.compressToMaxFileSize,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.compressToMaxFileSize =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.compressToMaxFileSize = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.compressToMaxFileSize = $$c
                                          }
                                        },
                                        _vm.setSavedSettingsToNull,
                                      ],
                                    },
                                  }),
                                  _vm._v(
                                    "\n\n                Compress to Max File Size\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.compressToMaxFileSize
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-6 form-row align-items-center",
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "w-75",
                                          attrs: {
                                            type: "number",
                                            placeholder: "Max file size",
                                          },
                                          on: {
                                            change: _vm.setSavedSettingsToNull,
                                          },
                                          model: {
                                            value:
                                              _vm.compressToMaxFileSizeValue,
                                            callback: function ($$v) {
                                              _vm.compressToMaxFileSizeValue =
                                                $$v
                                            },
                                            expression:
                                              "compressToMaxFileSizeValue",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v("KB"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row justify-content-end mt-3" },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: { click: _vm.showSaveSettingsModal },
                            },
                            [
                              _vm._v(
                                "\n              Save settings\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Saved settings" } },
                    [
                      _c("SavedDownloadSettingsTab", {
                        ref: "savedDownloadSettingsTab",
                        attrs: { "is-master-admin": _vm.isMasterAdmin },
                        on: { "settings-updated": _vm.onSettingsUpdated },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SaveDownloadSettingsModal", {
        ref: "saveDownloadSettingsModal",
        attrs: { "is-master-admin": _vm.isMasterAdmin },
        on: { saved: _vm.onSettingSaved },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }