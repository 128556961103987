import { render, staticRenderFns } from "./PageNumberUnderlyingShape.vue?vue&type=template&id=2e9244ca&scoped=true"
import script from "./PageNumberUnderlyingShape.vue?vue&type=script&lang=js"
export * from "./PageNumberUnderlyingShape.vue?vue&type=script&lang=js"
import style0 from "./PageNumberUnderlyingShape.vue?vue&type=style&index=0&id=2e9244ca&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9244ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_testing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e9244ca')) {
      api.createRecord('2e9244ca', component.options)
    } else {
      api.reload('2e9244ca', component.options)
    }
    module.hot.accept("./PageNumberUnderlyingShape.vue?vue&type=template&id=2e9244ca&scoped=true", function () {
      api.rerender('2e9244ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/Export/PageNumberUnderlyingShape.vue"
export default component.exports