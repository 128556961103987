var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center apply-block" },
    [
      _c("div", { staticClass: "mr-1" }, [_vm._v("\n    Apply to :\n  ")]),
      _vm._v(" "),
      _c("SelectTemplateDropdown", {
        attrs: {
          "local-storage-key":
            _vm.LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
        },
      }),
      _vm._v(" "),
      _c(
        "b-dropdown",
        {
          staticClass: "select-properties-dropdown mr-1",
          attrs: {
            size: "sm",
            variant: "primary",
            text: "Actions",
            "menu-class": "p-0",
          },
        },
        [
          _c(
            "b-list-group",
            _vm._l(_vm.properties, function (property, index) {
              return _c(
                "b-list-group-item",
                { key: index },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "select-size",
                      attrs: { size: "sm" },
                      on: {
                        change: function ($event) {
                          return _vm.updateSelectedProperties(property)
                        },
                      },
                      model: {
                        value: property.value,
                        callback: function ($$v) {
                          _vm.$set(property, "value", $$v)
                        },
                        expression: "property.value",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(property.text) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "mr-1",
          attrs: { size: "sm", variant: "primary" },
          on: { click: _vm.apply },
        },
        [_vm._v("\n    Apply\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }